body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.awssld__content > img,
    .awssld__content > video {
      width: auto !important;
      height: 120% !important;
      position: unset !important;
      top: unset !important;
      left: unset !important;
      -o-object-fit: cover !important;
    object-fit: cover !important;
  }

  .awssld__bar {
    background-color: #FF5B2B !important;
}

  @media only screen and (max-width: 500px) {
    .awssld__content > img,
    .awssld__content > video {
    -o-object-fit: cover !important;
    object-fit: cover !important;
    height: 105% !important;
  }
  }